import React, { useState, useEffect } from 'react';

const workouts = {
  day1: [
    { name: 'Push-ups', sets: 3, reps: '15-20' },
    { name: 'Pull-ups', sets: 3, reps: '5' },
    { name: 'Dumbbell Rows', sets: 3, reps: '12' },
    { name: 'Plank', sets: 3, reps: '45-60 seconds' },
  ],
  day2: [
    { name: 'Bodyweight Squats', sets: 3, reps: '20-25' },
    { name: 'Dumbbell Lunges', sets: 3, reps: '15 per leg' },
    { name: 'Calf Raises', sets: 3, reps: '20' },
  ],
  day3: [
    { name: 'Push-ups', sets: 3, reps: '15-20' },
    { name: 'Dumbbell Squats', sets: 3, reps: '15' },
    { name: 'Pull-ups', sets: 3, reps: '5' },
    { name: 'Plank', sets: 3, reps: '45-60 seconds' },
  ],
};

const WorkoutTracker = () => {
  const initialCompletedSets = {
    day1: Array(workouts.day1.length).fill(0),
    day2: Array(workouts.day2.length).fill(0),
    day3: Array(workouts.day3.length).fill(0),
  };

  const [completedSets, setCompletedSets] = useState(initialCompletedSets);
  const [timeLeft, setTimeLeft] = useState(0);
  const [workoutTime, setWorkoutTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let countdown;
    if (timeLeft > 0) {
      countdown = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timeLeft]);

  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setWorkoutTime((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isRunning]);

  const handleCheck = (day, exerciseIndex, setIndex) => {
    const newCompletedSets = { ...completedSets };

    if (newCompletedSets[day][exerciseIndex] > setIndex) {
      newCompletedSets[day][exerciseIndex] = setIndex;
      setTimeLeft(0);
    } else {
      newCompletedSets[day][exerciseIndex] = setIndex + 1;
      setTimeLeft(60);
    }

    setCompletedSets(newCompletedSets);
  };

  const handleStart = () => {
    setIsRunning(true);
  };

  const handleStop = () => {
    setIsRunning(false);
  };

  const renderWorkoutTable = (day, exercises) => (
    <table>
      <thead>
        <tr>
          <th>Exercise</th>
          <th>Sets</th>
          <th>Reps</th>
          <th>Complete</th>
        </tr>
      </thead>
      <tbody>
        {exercises.map((exercise, exerciseIndex) => (
          <tr key={exercise.name}>
            <td>{exercise.name}</td>
            <td>{exercise.sets}</td>
            <td>{exercise.reps}</td>
            <td>
              {[...Array(exercise.sets)].map((_, setIndex) => (
                <input
                  key={setIndex}
                  type="checkbox"
                  checked={completedSets[day][exerciseIndex] > setIndex}
                  onChange={() => handleCheck(day, exerciseIndex, setIndex)}
                />
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div>
      <h1>Workout Tracker</h1>
      <div className="timer" style={{ color: timeLeft <= 5 ? 'red' : 'black' }}>
        Next set in: {timeLeft}s
      </div>
      <div className="workout-timer">
        Total workout time: {formatTime(workoutTime)}
      </div>
      <button onClick={handleStart} disabled={isRunning}>
        Start Workout
      </button>
      <button onClick={handleStop} disabled={!isRunning}>
        Stop Workout
      </button>
      <h2>Day 1: Upper Body Strength</h2>
      {renderWorkoutTable('day1', workouts.day1)}
      <h2>Day 2: Lower Body Strength</h2>
      {renderWorkoutTable('day2', workouts.day2)}
      <h2>Day 3: Full Body and Core</h2>
      {renderWorkoutTable('day3', workouts.day3)}
    </div>
  );
};

export default WorkoutTracker;