import React from 'react';
import './App.css';
import WorkoutTracker from './WorkoutTracker';

function App() {
  return (
    <div className="App">
      <WorkoutTracker />
    </div>
  );
}

export default App;